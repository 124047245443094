import React, { useEffect, useState } from "react";
import { FooterContainerDesktop, FooterContainerMobile } from "./styles";

import LogoNew from "../../../assets/images/logo-negative.png";

import {  RiWhatsappFill } from "react-icons/ri";
import { ImFacebook } from "react-icons/im";
import { BsWhatsapp } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import { HiPhone } from "react-icons/hi";
import moment from "moment";
import { GrInstagram, GrLinkedinOption } from "react-icons/gr";
import RAIcon from "../../../assets/icons/RA.svg";
import { sanitizeParams } from "../../../utils/sanitize";

const Footer: React.FC = () => {
  const screenWidth = window.screen.width;
  const [isMobile, setIsMobile] = useState(window.screen.width <= 500);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [queryParams, setQueryParams] = useState<any>();

  useEffect(() => {
    const text = sanitizeParams(window.location.search);
    setQueryParams(text);
  }, [queryParams]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  window.addEventListener("resize", ajustarTela);

  function ajustarTela() {
    const tamanho = window.innerWidth;
    if (tamanho <= 1000) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }

  return (
    <>
          <a
        href="https://api.whatsapp.com/send/?phone=551140405686&text=Ol%C3%A1%2C+quero+vender+minha+cota+de+consorcio+para+a+Concash%21&type=phone_number&app_absent=0"
        target={"_blank"}
        className="wpp-anchor" rel="noreferrer"
      >
        <BsWhatsapp style={{ marginTop: 15 }} />
      </a>
      {!isMobile ? (
        <>
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              top: -180,
            }}
          >
            <img
              src={LogoNew}
              width={174}
              height={48}
              loading="lazy"
              alt="logo-new"
            />
          </div>
          <FooterContainerDesktop>
            <div className="footer-center">
              <div className="col-footer">
                <a href="/#como-funciona" className="link-list-desktop">
                  Como funciona
                </a>
                <a
                  href="https://blog.concash.com.br"
                  className="link-list-desktop"
                  target="_blank" rel="noreferrer"
                >
                  Blog
                </a>

                <a href={queryParams?.length != 0 ? ("/sobre"+queryParams) : "/sobre"} className="link-list-desktop">
                  Sobre nós
                </a>
                <a href={queryParams?.length != 0 ? ("/faq"+ queryParams) : "/faq"} className="link-list-desktop">
                  Perguntas frequentes
                </a>
                <a href={queryParams?.length != 0 ? ("/contatos"+queryParams) : "/contatos"} className="link-list-desktop">
                  Fale conosco
                </a>
                <a href={"https://parceiros.concash.com.br/parceiros-b2b"} className="link-list-desktop" target="_blank" rel="noreferrer">
                  Seja Parceiro
                </a>
                <a href={require("../../../assets/termo/Politica_Privacidade.pdf")} className="link-list-desktop" target="_blank" rel="noreferrer">
                  Termos e Política de Privacidade
                </a>
              </div>
              <div className="col-footer">
                <div>
                  <div className="contact-item" style={{ gap: 8 }}>
                    <FiMail size={16} />
                    <a
                      href="mailto:voce@concash.com.br"
                      target="_blank"
                      style={{ textDecoration: "none", color: "#000" }}
                      className="text-contact" rel="noreferrer"
                    >
                      voce@concash.com.br
                    </a>
                  </div>
                  <div className="contact-item" style={{ marginTop: 20 }}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: 8 }}
                    >
                      <HiPhone size={16} />
                      <a
                        href="tel:+551140405686"
                        className="text-contact"
                        target="_blank"
                        style={{ textDecoration: "none", color: "#000" }} rel="noreferrer"
                      >
                        (11) 4040-5686
                      </a>
                    </div>
                  </div>

                  <div className="contact-item" style={{ marginTop: 20 }}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: 8 }}
                    >
                      <RiWhatsappFill size={16} />
                      <a
                        href="https://api.whatsapp.com/send/?phone=551140405686&text=Ol%C3%A1%2C+quero+vender+minha+cota+de+consorcio+para+a+Concash%21&type=phone_number&app_absent=0"
                        className="text-contact"
                        target="_blank"
                        style={{ textDecoration: "none", color: "#000" }} rel="noreferrer"
                      >
                        (11) 4040-5686
                      </a>
                    </div>
                  </div>
                  <div style={{ paddingLeft: 25 }}>
                    <span className="subtext">WhatsApp</span>
                  </div>
                  <div className="contact-item" style={{ marginTop: 20 }}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: 8 }}
                    >
                      <span className="text-contact">
                        <b style={{ fontWeight: "bold" }}>
                          Horário de Atendimento
                        </b>
                        <br /> Segunda a sexta, das 9h às 18h
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-footer2">
                <div className="contact-item">
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 8 }}
                  >
                    <span className="text-contact">Siga a gente:</span>
                  </div>
                </div>
                <div className="contact-item" style={{ gap: 15 }}>
                  <a
                    href="https://www.facebook.com/voceconcash/"
                    className="circle"
                    target="_blank" rel="noreferrer"
                  >
                    <ImFacebook />
                  </a>
                  <a
                    href="https://www.instagram.com/voceconcash"
                    className="circle"
                    target="_blank" rel="noreferrer"
                  >
                    <GrInstagram />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/voceconcash"
                    className="circle"
                    target="_blank" rel="noreferrer"
                  >
                    <GrLinkedinOption />
                  </a>
                </div>
                <div className="contact-item" style={{ marginTop: 15 }}>
                  <a
                    href="https://www.reclameaqui.com.br/empresa/concash-intermediacao-de-negocios-e-participacoes/"
                    style={{ textDecoration: "none" }}
                    target="_blank" rel="noreferrer"
                  >
                    <img
                      loading="lazy"
                      src={RAIcon}
                      width={198}
                      height={49}
                      alt="RA"
                    />
                  </a>
                </div>
                <div className="contact-item" style={{ marginTop: 15 }}>
                  <span className="subtext">
                    © Copyright {moment(new Date()).format("YYYY")} Concash.{" "}
                    <br /> Todos os direitos reservados
                  </span>
                </div>
                <div className="contact-item" style={{ marginTop: 5 }}>
                  <span className="subtext">
                    Concash Intermediação de Negócios e Participações LTDA.{" "}
                    <br />
                    CNPJ: 34.737.980/0001-21
                  </span>
                </div>
              </div>
            </div>
          </FooterContainerDesktop>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              top: -210,
              margin: "0 40px",
              paddingBottom: 40,
              borderBottom: "1px solid #DDD",
            }}
          >
            <img
              src={LogoNew}
              width={162}
              height={44}
              loading="lazy"
              alt="logo-n"
            />
          </div>
          <FooterContainerMobile>
            <div className="footer-center">
              <div className="col-footer">
                <a href="/#como-funciona" className="link-list-mobile">
                  Como funciona
                </a>
                <a
                  href="https://blog.concash.com.br"
                  className="link-list-mobile"
                  target="_blank" rel="noreferrer"
                >
                  Blog
                </a>
                <a href={queryParams?.length != 0 ? ("/sobre"+queryParams) : "/sobre"} className="link-list-mobile">
                  Sobre nós
                </a>
                <a href={queryParams?.length != 0 ? ("/faq"+queryParams) : "/faq"} className="link-list-mobile">
                  Perguntas frequentes
                </a>
                <a href={queryParams?.length != 0 ? ("/contatos"+queryParams) : "/contatos"} className="link-list-mobile">
                  Fale conosco
                </a>
                <a href={"https://parceiros.concash.com.br/parceiros-b2b"} className="link-list-mobile">
                  Seja Parceiro
                </a>
              </div>
              <div className="col-footer2">
                <div>
                  <div className="contact-item" style={{ gap: 8 }}>
                    <FiMail size={16} />
                    <a
                      href="mailto:voce@concash.com.br"
                      target="_blank"
                      style={{ textDecoration: "none", color: "#000" }}
                      className="text-contact" rel="noreferrer"
                    >
                      voce@concash.com.br
                    </a>
                  </div>
                  <div className="contact-item" style={{ marginTop: 20 }}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: 8 }}
                    >
                      <HiPhone size={16} />
                      <a
                        href="tel:+551140405686"
                        target="_blank"
                        className="text-contact"
                        style={{ textDecoration: "none", color: "#000" }} rel="noreferrer"
                      >
                        (11) 4040-5686
                      </a>
                    </div>
                  </div>

                  <div className="contact-item" style={{ marginTop: 20 }}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: 8 }}
                    >
                      <RiWhatsappFill size={16} />
                      <a
                        href="https://api.whatsapp.com/send/?phone=551140405686&text=Ol%C3%A1%2C+quero+vender+minha+cota+de+consorcio+para+a+Concash%21&type=phone_number&app_absent=0"
                        className="text-contact"
                        target="_blank"
                        style={{ textDecoration: "none", color: "#000" }} rel="noreferrer"
                      >
                        (11) 4040-5686
                      </a>
                    </div>
                  </div>
                  <div style={{ paddingLeft: 25 }}>
                    <span className="subtext">WhatsApp</span>
                  </div>
                  <div className="contact-item" style={{ marginTop: 20 }}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: 8 }}
                    >
                      <span className="text-contact">
                        <b style={{ fontWeight: "bold" }}>
                          Horário de Atendimento
                        </b>
                        <br /> Segunda a sexta, das 9h às 18h
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-footer-center">
                <div className="contact-item">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 8,
                    }}
                  >
                    <span className="text-contact-center">Siga a gente:</span>
                  </div>
                </div>
                <div className="contact-item" style={{ gap: 15 }}>
                  <a
                    href="https://www.facebook.com/voceconcash/"
                    className="circle"
                    target="_blank" rel="noreferrer"
                  >
                    <ImFacebook />
                  </a>
                  <a
                    href="https://www.instagram.com/voceconcash"
                    className="circle"
                    target="_blank" rel="noreferrer"
                  >
                    <GrInstagram />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/voceconcash"
                    className="circle"
                    target="_blank" rel="noreferrer"
                  >
                    <GrLinkedinOption />
                  </a>
                </div>
                <div className="contact-item" style={{ marginTop: 15 }}>
                  <a
                    href="https://www.reclameaqui.com.br/empresa/concash-intermediacao-de-negocios-e-participacoes/"
                    target="_blank"
                    style={{ textDecoration: "none" }} rel="noreferrer"
                  >
                    <img
                      loading="lazy"
                      src={RAIcon}
                      width={198}
                      height={49}
                      alt="RA"
                    />
                  </a>
                </div>
                <div className="contact-item" style={{ marginTop: 15 }}>
                  <span className="subtext">
                    © Copyright {moment(new Date()).format("YYYY")} Concash.{" "}
                    <br /> Todos os direitos reservados
                  </span>
                </div>
                <div
                  className="contact-item"
                  style={{ marginTop: 5, marginBottom: 10 }}
                >
                  <span className="subtext" style={{ textAlign: "center" }}>
                    Concash Intermediação de Negócios e Participações LTDA.{" "}
                    <br />
                    CNPJ: 34.737.980/0001-21
                  </span>
                </div>
              </div>
            </div>
          </FooterContainerMobile>
        </>
      )}
    </>
  );
};

export default Footer;
